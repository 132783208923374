var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        white:
            (_vm.$vuetify.breakpoint.mdAndDown || _vm.forceModalPreview) &&
            !_vm.$vuetify.dark,
        'grey darken-3':
            (_vm.$vuetify.breakpoint.mdAndDown || _vm.forceModalPreview) &&
            _vm.$vuetify.dark,
    }},[_c('v-card',{staticClass:"pt-3",class:{
            'ma-1 pt-12': _vm.$vuetify.breakpoint.lgAndUp && !_vm.forceModalPreview,
        },style:({
            marginTop:
                _vm.$vuetify.breakpoint.lgAndUp && !_vm.forceModalPreview
                    ? '-20px !important'
                    : 0,
        }),attrs:{"color":"transparent"}},[_c('v-subheader',{staticClass:"v-subheader--multiline primary--text mb-3"},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.selectedItem.heading))]),_c('v-spacer'),_c('v-btn',{attrs:{"left":"","text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('closeItem')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),(_vm.selectedItem.sourceURL)?_c('v-card-actions',[(_vm.selectedItem.sourceURL)?_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","tag":"a","small":"","text":"","href":_vm.selectedItem.sourceURL,"target":"_blank"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t('community.goToReport'))+" ")],1):_vm._e()],1):_vm._e(),_c('v-card-text',{staticClass:"pt-0 text-smaller"},[_c('p',[_vm._v(" "+_vm._s(_vm.itemDate)),(_vm.countryNames)?_c('span',[_vm._v(", "+_vm._s(_vm.countryNames))]):_vm._e()]),(_vm.selectedItem.category === 'penalty_fee')?[_c('h3',{staticClass:"pa-0",domProps:{"textContent":_vm._s(
                        _vm.$t(
                            'community.penaltyFeeCategoryName.' +
                                _vm.selectedItem.penaltyFeeCategory
                        )
                    )}})]:_vm._e(),(
                    (_vm.selectedItem.nameOfAuthority &&
                        _vm.selectedItem.category === 'authority_news') ||
                        _vm.selectedItem.category === 'penalty_fee'
                )?[_c('h3',{staticClass:"pa-0",domProps:{"textContent":_vm._s(_vm.selectedItem.nameOfAuthority)}})]:_vm._e(),(
                    _vm.selectedItem.departmentCategory &&
                        _vm.selectedItem.category === 'scenario'
                )?[_c('h3',{staticClass:"pa-0",domProps:{"textContent":_vm._s(_vm.departmentCategory)}})]:_vm._e(),_c('div',{staticClass:"tiptap-content",class:'tiptap-content--' + _vm.$vuetify.breakpoint.name,domProps:{"innerHTML":_vm._s(_vm.textContent)}}),(
                    _vm.selectedItem.contentAttachments &&
                        _vm.selectedItem.contentAttachments.length > 0
                )?_vm._l((_vm.selectedItem.contentAttachments),function(attachment,j){return _c('div',{key:j},[_c('v-icon',[_vm._v("attachment")]),_vm._v(" "),_c('a',{staticClass:"attachment-link word-break--all",attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(
                                attachment.fileUrl,
                                attachment.title
                            )}}},[_vm._v(_vm._s(attachment.title))]),(_vm.getCountryName(attachment.language))?_c('span',[_vm._v(" ("+_vm._s(_vm.getCountryName(attachment.language))+")")]):_vm._e()],1)}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }