<template>
    <div
        :class="{
            white:
                ($vuetify.breakpoint.mdAndDown || forceModalPreview) &&
                !$vuetify.dark,
            'grey darken-3':
                ($vuetify.breakpoint.mdAndDown || forceModalPreview) &&
                $vuetify.dark,
        }"
    >
        <v-card
            color="transparent"
            class="pt-3"
            :class="{
                'ma-1 pt-12': $vuetify.breakpoint.lgAndUp && !forceModalPreview,
            }"
            :style="{
                marginTop:
                    $vuetify.breakpoint.lgAndUp && !forceModalPreview
                        ? '-20px !important'
                        : 0,
            }"
        >
            <v-subheader class="v-subheader--multiline primary--text mb-3">
                <span class="text-subtitle-1 font-weight-bold">{{
                    selectedItem.heading
                }}</span>
                <v-spacer></v-spacer>
                <v-btn
                    left
                    text
                    icon
                    color="primary"
                    @click="$emit('closeItem')"
                >
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </v-subheader>
            <v-card-actions v-if="selectedItem.sourceURL">
                <v-btn
                    v-if="selectedItem.sourceURL"
                    color="primary"
                    tag="a"
                    small
                    text
                    class="ml-0"
                    :href="selectedItem.sourceURL"
                    target="_blank"
                >
                    <v-icon small left>mdi-open-in-new</v-icon>
                    {{ $t('community.goToReport') }}
                </v-btn>
            </v-card-actions>
            <v-card-text class="pt-0 text-smaller">
                <p>
                    {{ itemDate
                    }}<span v-if="countryNames">, {{ countryNames }}</span>
                </p>
                <template v-if="selectedItem.category === 'penalty_fee'">
                    <h3
                        class="pa-0"
                        v-text="
                            $t(
                                'community.penaltyFeeCategoryName.' +
                                    selectedItem.penaltyFeeCategory
                            )
                        "
                    />
                </template>
                <template
                    v-if="
                        (selectedItem.nameOfAuthority &&
                            selectedItem.category === 'authority_news') ||
                            selectedItem.category === 'penalty_fee'
                    "
                >
                    <h3 class="pa-0" v-text="selectedItem.nameOfAuthority" />
                </template>
                <template
                    v-if="
                        selectedItem.departmentCategory &&
                            selectedItem.category === 'scenario'
                    "
                >
                    <h3 class="pa-0" v-text="departmentCategory" />
                </template>
                <div class="tiptap-content" :class="'tiptap-content--' + $vuetify.breakpoint.name" v-html="textContent"></div>
                <template
                    v-if="
                        selectedItem.contentAttachments &&
                            selectedItem.contentAttachments.length > 0
                    "
                >
                    <div
                        v-for="(attachment,
                        j) in selectedItem.contentAttachments"
                        :key="j"
                    >
                        <v-icon>attachment</v-icon>&nbsp;<a
                            class="attachment-link word-break--all"
                            @click.prevent="
                                downloadFile(
                                    attachment.fileUrl,
                                    attachment.title
                                )
                            "
                            target="_blank"
                            >{{ attachment.title }}</a
                        >
                        <span v-if="getCountryName(attachment.language)">
                            ({{ getCountryName(attachment.language) }})</span
                        >
                    </div>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO, dateTypes, formatDate } from '@/utils/dateFns';

export default {
    name: 'ItemPreview',
    props: {
        selectedItem: Object,
        departmentCategoryItems: Array,
        forceModalPreview: Boolean,
    },
    i18n: {
        messages: {
            en: require('@/locales/Community/en.json'),
            de: require('@/locales/Community/de.json'),
        },
    },
    data() {
        return {
            statusItems: ['draft', 'current', 'archived'],
        };
    },
    methods: {
        getCountryName(countryCode) {
            const country = this.findCountryByCode(countryCode);
            return country && country.countryName ? country.countryName : null;
        },
        downloadFile(fileUrl, fileName) {
            this.$getFile(fileUrl, fileName);
        },
    },
    computed: {
        ...mapGetters({
            findCountryByCode: 'countries/findByCountryCode',
        }),
        itemDate() {
            if (this.selectedItem.date.substring(0, 4) === '1920') {
                return this.$i18n.t('community.groups.date.unknownDate');
            }
            return formatDate(
                parseISO(this.selectedItem.date),
                dateTypes.fullDate
            );
        },
        textContent() {
            if (this.selectedItem.category === 'lea_news') {
                return this.selectedItem.content;
            }
            let content = '';
            if (
                this.selectedItem.summary &&
                this.selectedItem.summary.length > 8
            ) {
                content = this.selectedItem.summary;
                if (this.selectedItem.category !== 'template') {
                    content += this.selectedItem.content;
                }
            } else {
                content = this.selectedItem.content;
            }
            return content;
        },
        countryNames() {
            let strCountries = '';
            this.selectedItem.countries.forEach((x, i) => {
                const country = this.findCountryByCode(x);
                strCountries += country
                    ? i > 1
                        ? ', ' + country.countryName
                        : country.countryName
                    : null;
            });
            return strCountries;
        },
        departmentCategory() {
            if (
                this.selectedItem.departmentCategory &&
                parseInt(this.selectedItem.departmentCategory) > 0
            ) {
                const departmentCategory = this.departmentCategoryItems.find(
                    () => this.selectedItem.departmentCategory
                );
                return departmentCategory.title || null;
            }
            return null;
        },
    }
};
</script>
